
import { Vue, Component } from "vue-property-decorator";
import PageTabs from "../components/PageTabs.vue";

const INDEX_ROUTE = "merchants-index";

@Component({
  components: {
    PageTabs,
  },
})
export default class Merchants extends Vue {
  private get currentTab() {
    return this.$route.query.tab || this.$route.name;
  }

  private routeReplace(tab = "activated") {
    if (this.$route.name === INDEX_ROUTE) {
      const tabTitle = this.statuses.find(
        ({ value }: { value: string }) => value === tab
      )?.label;

      this.$router.replace({
        name: INDEX_ROUTE,
        query: { tab, tabTitle: tabTitle || "" },
      });
    } else {
      this.$router.push({ name: tab });
    }
  }

  private get statuses() {
    return [
      { label: "Not yet Launched", value: "pending" },
      { label: "Active", value: "activated" },
      { label: "Inactive", value: "deactivated" },
      { label: "Unsubscribed", value: "unsubscribed" },
    ];
  }

  private get tabs() {
    if (this.$route.params.merchantUuid) {
      return this.merchantTabs;
    }
    return this.statuses;
  }

  private get merchantTabs() {
    return [
      { label: "Merchants Details", value: "merchant-order-history" },
      { label: "Registration Details", value: "registration-details" },
      { label: "Settings", value: "merchant-settings" },
    ];
  }

  private mounted() {
    if (this.$route.name === INDEX_ROUTE && !this.$route.query.tab) {
      this.routeReplace();
    }
  }
}
